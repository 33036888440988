/* eslint-disable import/no-cycle */
import { getI18nLanguage } from '@/plugins/i18n'
import { apiHttp } from '../axiosApi'
import {
  matchPublic,
  matchPrivate,
  matchOpenCode,
  matchCode,
  matchGameOpenCode,
  matchGameCode,
  matchUserCheckout,
  matchUserPay,
  matchInfo,
  landingJoinGame,
  sendReportEmail,
  landingPayVideo,
  circleInvitation,
  landingRecordings,
  landingRecordingsHashid,
  landingVideoOrder,
  matchUserLeave,
  matchPlayersQty,
  matchUserJoin,
  matchUserUploadAvatar,
  matchTeamPath,
  matchTeamUser,
  matchTeamUploadLogo,
  matchTeamAssociation,
} from '../config/apiRoute'

export const getMatchPublic = code => apiHttp('GET', `${matchPublic}${code}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${code}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})
export const getMatchPrivate = code => apiHttp('GET', `${matchPrivate}${code}`)

export const getMatchOpenCode = code => apiHttp('GET', `${matchOpenCode}${code}`, null, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Basic ${btoa(`${code}`)}`,
    'Accept-Language': getI18nLanguage(),
  },
})
export const getMatchCode = code => apiHttp('GET', `${matchCode}${code}`)

export const editMatchInfo = (code, info) => apiHttp('PUT', `${matchInfo}${code}`, info)
export const joinGame = info => apiHttp('POST', `${landingJoinGame}`, info)
export const payVideo = (code, info) => apiHttp('PUT', `${landingPayVideo}${code}`, { info })

export const reportIssue = (code, text) => apiHttp('GET', `${sendReportEmail}${code}`, null, { params: { text_report: text } })

export const getVideoCircle = async hashid => apiHttp(
  'GET',
  `${circleInvitation}${hashid}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordings = async (facilityId, fieldId, videoDate, startTime, lastVideoId) => apiHttp(
  'GET',
  `${landingRecordings}${facilityId}/${fieldId}/${videoDate}/${startTime}/${lastVideoId}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordingsHashid = async hashid => apiHttp(
  'GET',
  `${landingRecordingsHashid}${hashid}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getRecordingVideoOrder = async (groupId, facilityId, fieldId, videoDate, startTime) => apiHttp(
  'GET',
  `${landingVideoOrder}${groupId}/${facilityId}/${fieldId}/${videoDate}/${startTime}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
)

export const getMatchGameOpenCode = async (code, team) => apiHttp(
  'GET',
  team ? `${matchGameOpenCode}${code}/${team}` : `${matchGameOpenCode}${code}`,
  {},
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': getI18nLanguage(),
    },
  },
  true,
  'v2',
)
export const getMatchGameCode = async (code, team) => apiHttp(
  'GET',
  team ? `${matchGameCode}${code}/${team}` : `${matchGameCode}${code}`,
  {},
  {},
  true,
  'v2',
)
export const getMatchUserCheckout = async (code, team) => apiHttp(
  'GET',
  team ? `${matchUserCheckout}${code}/${team}` : `${matchUserCheckout}${code}`,
  {},
  {},
  true,
  'v2',
)
export const addMatchUserPay = async (code, body) => apiHttp(
  'PUT',
  `${matchUserPay}${code}`,
  body,
  {},
  true,
  'v2',
)
export const leaveMatchUser = async code => apiHttp(
  'PUT',
  `${matchUserLeave}${code}`,
  {},
  {},
  true,
  'v2',
)
export const changeMatchPlayersQty = async (code, body) => apiHttp(
  'PUT',
  `${matchPlayersQty}${code}`,
  body,
  {},
  true,
  'v2',
)
export const joinMatchUser = async (code, body) => apiHttp(
  'PUT',
  `${matchUserJoin}${code}`,
  body,
  {},
  true,
  'v2',
)
export const addUserUploadAvatar = async body => {
  const { accessToken } = localStorage

  return apiHttp(
    'PUT',
    `${matchUserUploadAvatar}`,
    body,
    {
      'Content-Type': 'multipart/form-data',
      'el-token': `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    true,
    'v2',
  )
}
export const createTeam = async body => apiHttp(
  'POST',
  `${matchTeamPath}`,
  body,
  {},
  true,
  'v2',
)
export const getUserTeams = async () => apiHttp(
  'GET',
  `${matchTeamUser}`,
  {},
  {},
  true,
  'v2',
)
export const uploadTeamLogo = async (body, id) => {
  const { accessToken } = localStorage

  return apiHttp(
    'PUT',
    `${matchTeamUploadLogo}${id}`,
    body,
    {
      'Content-Type': 'multipart/form-data',
      'el-token': `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    true,
    'v2',
  )
}
export const associateMatchTeam = async body => apiHttp(
  'POST',
  `${matchTeamAssociation}`,
  body,
  {},
  true,
  'v2',
)
