// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch, ref, computed } from '@vue/composition-api'
import currencyFormatter from 'currency-formatter'
import moment from 'moment'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

// Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K' : Math.sign(num) * Math.abs(num)
export const kFormatter = num => {
  if (Math.abs(num) > 999) return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}K`

  return Math.sign(num) * Math.abs(num)
}

export const formatDateSlash = date => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  if (year) return `${day}/${month}/${year}`

  return `${month}/${year}`
}

export const formatDateBar = date => {
  const arryData = date.split('/')
  const arryReverse = arryData.reverse()

  return arryReverse.join('-')
}

export const getAge = date => {
  const ageInMilliseconds = new Date() - new Date(date)

  return Math.floor(ageInMilliseconds / 1000 / 60 / 60 / 24 / 365) // convert to years
}

export const getAgeOld = (dA, dB) => {
  const dateA = moment(dA)
  const dateB = moment(dB)

  return dateA.from(dateB) // time old
}

export const getDateDiff = (dA, dB) => {
  const dateA = moment(dA)
  const dateB = moment(dB)

  const years = dateB.diff(dateA, 'years')
  const months = dateB.diff(dateA, 'months')
  const weeks = dateB.diff(dateA, 'weeks')
  if (years > 0) return `${years} ${years === 1 ? 'year' : 'years'}`
  if (months > 0) return `${months} ${months === 1 ? 'month' : 'months'}`

  return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`
}

export const formatCurrency = (amount, locale = 'usd') => currencyFormatter.format(amount, { locale: locale === 'usd' ? 'en-US' : 'nl-NL' })

export const unformatCurrency = (amount, locale = 'usd') => currencyFormatter.unformat(amount, { locale: locale === 'usd' ? 'en-US' : 'nl-NL' })

export const openTab = (url, target = '_self') => {
  const a = window.document.createElement('a')
  a.target = target
  a.href = url

  // Dispatch fake click
  const e = window.document.createEvent('MouseEvents')
  e.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}

export const getOS = () => {
  const ua = navigator.userAgent
  if (/android/i.test(ua)) {
    return 'Android'
  }

  if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
    return 'iOS'
  }

  if (ua.indexOf('Win') !== -1) {
    return 'Windows'
  }

  if (ua.indexOf('Mac') !== -1) {
    return 'MacOS'
  }

  return 'Other'
}

export const isAndroid = () => getOS() === 'Android'

export const isIOS = () => getOS() === 'iOS'

export const isDev = computed(() => {
  const ENV = process.env
  if (['localhost', '127.0.0.1'].includes(window.location.hostname) || ENV.VUE_APP_DEPLOYMENT_ENV.includes('staging')) return true

  return false
})

export const downloadAppByOS = (urlAndroid, urlIOS, urlOther, openApp = false) => {
  try {
    if (isAndroid()) {
      if (openApp) {
        window.location = urlAndroid
        const clickedAt = +new Date()
        setTimeout(() => {
          if (+new Date() - clickedAt < 2000) {
            window.location = 'https://play.google.com/store/apps/details?id=com.fanaty'
          }
        }, 500)
      } else window.open(urlAndroid, '_blank')
    }
    if (isIOS()) {
      if (openApp) {
        window.open().location = urlIOS
        const clickedAt = +new Date()
        setTimeout(() => {
          if (+new Date() - clickedAt < 2000) {
            window.open().location = 'https://apps.apple.com/us/app/fanaty/id1461051554'
          }
        }, 500)
      } else openTab(urlIOS)
    }
    if (getOS() === 'MacOS') {
      openTab(urlOther)
    }
    if (getOS() === 'Windows' || getOS() === 'Other') {
      window.open(urlOther, '_blank')
    }
  } catch (e) {
    openTab('https://fanaty.com/download')
  }
}

const timer = ref(null)
export const defaultDownloadAppByOS = (newTab = false) => {
  clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    try {
      if (isAndroid()) {
        if (!newTab) {
          window.location = 'https://play.google.com/store/apps/details?id=com.fanaty'
        } else window.open('https://play.google.com/store/apps/details?id=com.fanaty', '_blank')
      }
      if (isIOS()) {
        if (!newTab) {
          window.open().location = 'https://apps.apple.com/us/app/fanaty/id1461051554'
        } else openTab('https://apps.apple.com/us/app/fanaty/id1461051554', '_blank')
      }
      if (getOS() === 'MacOS') {
        openTab('https://www.fanaty.com')
      }
      if (getOS() === 'Windows' || getOS() === 'Other') {
        if (!newTab) window.location = 'https://www.fanaty.com'
        else window.open('https://www.fanaty.com', '_blank')
      }
    } catch (e) {
      openTab('https://fanaty.com')
    }
  }, 200)
}

export const customDeepLink = (type = 'video', id = null) => {
  const deepLinkVideo = 'fanaty://video_detail/'
  const deepLinkVideoIOS = `${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/video_detail/`

  const deepLinkMatch = 'fanaty://match_detail/'
  const deepLinkMatchIOS = `${isDev.value ? 'https://dev-console.fanaty.com' : 'https://console.fanaty.com'}/match_detail/`

  const isAnd = isAndroid()
  const isIOScc = isIOS()

  // eslint-disable-next-line no-alert
  if (isDev.value) alert(`isAndroid value: ${isAnd}`)
  // eslint-disable-next-line no-alert
  if (isDev.value) alert(`isIOS value: ${isIOScc}`)

  if (isAndroid() || isIOS()) {
    const dLink = type === 'video' ? `${deepLinkVideo}${id}` : `${deepLinkMatch}${id}`
    const dLinkOISO = type === 'video' ? `${deepLinkVideoIOS}${id}` : `${deepLinkMatchIOS}${id}`

    if (isIOS()) {
      // eslint-disable-next-line no-alert
      if (isDev.value) alert(`deepLinkMatchIOS value: ${dLinkOISO}`)
      openTab(dLinkOISO)
    } else {
      // eslint-disable-next-line no-alert
      if (isDev.value) alert(`deepLinkVideoAndroid value: ${dLink}`)
      window.location = dLink
    }
  }
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isObjEmpty = value => Object.keys(value).length === 0

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}

export const getRGBColor = (colorStr, theme) => {
  const rgbColor = hexToRgb(theme.currentTheme[colorStr])

  return `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`
}

export const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) return true

  return false
}

export const toCapitalize = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })

  return capitalizedArray.join(' ')
}

export const formatStdDate = (dateStr = '2024-11-15 23:35', dateFormat = 'date', is12Hour = true, lang = 'en') => {
  let format = 'ddd, MMM DD, YYYY'
  if (dateFormat === 'date') format = 'ddd, MMM DD, YYYY'
  if (dateFormat === 'datelist') format = 'YYYY - MMM - DD'
  if (dateFormat === 'datetime') format = is12Hour ? 'ddd, MMM DD, YYYY h:mm A' : 'ddd, MMM DD, YYYY HH:mm'
  if (dateFormat === 'datetime-custom') {
    format = is12Hour ? 'ddd, MMM DD, YYYY h:mm A' : 'ddd, MMM DD, YYYY HH:mm'

    return toCapitalize(moment(dateStr, 'DD/MM/YYYY HH:mm:ss').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'day') {
    format = 'DD'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'month-str') {
    format = 'MMMM'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'datetime-landing') format = 'dddd DD MMM'
  if (dateFormat === 'monthtime') {
    format = is12Hour ? 'MMM DD, h:mm A' : 'MMM DD, YYYY HH:mm'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'time') {
    format = is12Hour ? 'h:mm A' : 'HH:mm'

    return toCapitalize(moment(`2000-01-01 ${dateStr}`, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'time-short') {
    format = is12Hour ? 'h:mm A' : 'HH:mm'

    return toCapitalize(moment(`2000-01-01 ${dateStr}`, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '').replaceAll(' ', '')
  }
  if (dateFormat === 'video-card-date') {
    format = 'MMM DD, YYYY'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }
  if (dateFormat === 'video-card-time') {
    format = is12Hour ? 'ddd, h:mm A' : 'ddd, HH:mm'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }

  if (dateFormat === 'video-card-time') {
    format = is12Hour ? 'ddd, h:mm A' : 'ddd, HH:mm'

    return toCapitalize(moment(dateStr, 'YYYY-MM-DD HH:mm').locale(lang).format(format)).replaceAll('.', '')
  }

  return toCapitalize(moment(dateStr).locale(lang).format(format)).replaceAll('.', '')
}

export const detectarNavegador = () => {
  const { userAgent } = navigator

  if (userAgent.toLowerCase().includes('chrome')) {
    return 'google chrome'
  } if (userAgent.toLowerCase().includes('firefox')) {
    return 'mozilla firefox'
  } if (userAgent.toLowerCase().includes('safari')) {
    return 'safari'
  } if (userAgent.toLowerCase().includes('edge')) {
    return 'microsoft edge'
  } if (userAgent.toLowerCase().includes('msie') || userAgent.toLowerCase().includes('trident')) {
    return 'internet explorer'
  }

  return 'other'
}

export const formatPhoneNumber = phoneNumber => {
  if (phoneNumber) {
    const visibleDigits = 4
    const lastDigits = phoneNumber.slice(-visibleDigits)

    return `***${lastDigits}`
  }

  return ''
}

export const formatEmail = email => {
  if (!email) return ''
  const [localPart, domain] = email.split('@')
  const firstLetter = localPart[0]

  return `${firstLetter}***@${domain}`
}

export const formatButtonText = (baseText, email, maxLength = 34) => {
  const fullText = `${baseText} ${email}`
  if (fullText.length > maxLength) {
    const visibleText = fullText.slice(0, maxLength - 3)

    return `${visibleText}...`
  }

  return fullText
}
