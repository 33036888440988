export default {
  Billing_to: 'Facturación Para',
  Dashboards: 'Tableros',
  Dashboard: 'Tablero',
  CRM: 'CRM',
  Analytics: 'Analiticas',
  eCommerce: 'eCommerce',
  Calendar: 'Calendario',
  Chat: 'Chat',
  chat_support: 'Chat de Soporte',
  Email: 'Correo electrónico',
  Invoice: 'Factura',
  Invoice_to: 'Factura Para',
  Receipt: 'Recibo',
  List: 'Lista',
  Preview: 'Vista previa',
  Edit: 'Editar',
  Add: 'Agregar',
  User: 'Usuario',
  'User List': 'Lista de usuarios',
  'User View': 'Vista de usuario',
  'APPS AND PAGES': 'APLICACIONES Y PÁGINAS',
  Pages: 'Páginas',
  Authentication: 'Autenticación',
  'Login V1': 'Iniciar sesión V1',
  'Login V2': 'Iniciar sesión V2',
  'Register V1': 'Registro V1',
  'Register V2': 'Registro V2',
  'Forgot Password V1': 'Forgot Password V1',
  'Forgot Password V2': 'Forgot Password V2',
  'Reset Password V1': 'Reset Password V1',
  'Reset Password V2': 'Reset Password V2',
  Miscellaneous: 'Miscelaneos',
  'Under Maintenance': 'En mantenimiento',
  Error: 'Error',
  'Coming Soon': 'Próximamente',
  'Not Authorized': 'No autorizado',
  'Knowledge Base': 'Base de conocimientos',
  'Account Settings': 'Configuración de la cuenta',
  Pricing: 'Precios',
  FAQ: 'Preguntas frecuentes',
  'USER INTERFACE': 'INTERFAZ DE USUARIO',
  Typography: 'Tipografía',
  Icons: 'Iconos',
  Gamification: 'Gamificación',
  Cards: 'Tarjetas',
  Basic: 'Básico',
  Statistics: 'Estadísticas',
  Advance: 'Avance',
  Actions: 'Acciones',
  Action: 'Acción',
  Chart: 'Gráfico',
  Components: 'Componentes',
  Alert: 'Alerta',
  Avatar: 'Avatar',
  Badge: 'Insignia',
  Button: 'Botón',
  Menu: 'Menú',
  'Expansion Panel': 'Panel de expansión',
  Dialog: 'Diálogo',
  Pagination: 'Paginación',
  Tabs: 'Pestañas',
  Tooltip: 'Información sobre herramientas',
  Chip: 'Chip',
  Snackbar: 'Snackbar',
  Stepper: 'Paso a paso',
  Timeline: 'Línea de tiempo',
  Treeview: 'Vista de árbol',
  'FORMS & TABLES': 'FORMULARIOS Y TABLAS',
  'Forms Elements': 'Elementos de Formularios',
  Autocomplete: 'Autocompletar',
  Checkbox: 'Casilla de verificación',
  Combobox: 'Cuadro combinado',
  'File Input': 'Entrada de archivo',
  Radio: 'Radio',
  'Range Slider': 'Control deslizante de rango',
  Select: 'Seleccionar',
  Slider: 'Control deslizante',
  Switch: 'Interruptor',
  Textarea: 'Textarea',
  Textfield: 'Campo de texto',
  'Date Picker': 'Selector de fecha',
  'Month Picker': 'Selector de mes',
  'Time Picker': 'Selector de tiempo',
  Rating: 'Calificación',
  'Form Layout': 'Diseño de formulario',
  'Form validation': 'Validación de formulario',
  Tables: 'Tablas',
  'Simple Table': 'Tabla simple',
  Datatable: 'Tabla de datos',
  'CHARTS AND MAPS': 'GRÁFICOS Y MAPAS',
  Charts: 'Gráficos',
  'Apex Chart': 'Gráfico de Apex',
  Chartjs: 'Chartjs',
  'Leaflet Maps': 'Mapas de folletos',
  Others: 'Otros',
  'Menu Levels': 'Niveles de menú',
  'Menu Level 2.1': 'Nivel de menú 2.1',
  'Menu Level 2.2': 'Nivel de menú 2.2',
  'Menu Level 3.1': 'Nivel de menú 3.1',
  'Menu Level 3.2': 'Nivel de menú 3.2',
  'Disabled Menu': 'Menú desactivado',
  'Raise Support': 'Elevar soporte',
  Documentation: 'Documentación',
  'Charts & Maps': 'Gráficos y mapas',
  Apps: 'Aplicaciones',
  'User Interface': 'Interfaz de usuario',
  'Forms & Tables': 'Formularios y tablas',
  'Buttom Options': 'Opciones de botón',
  VIEWS: 'VISTAS',
  Users: 'Usuarios',
  Transactions: 'Transacciones',
  'Access Control': 'Control de Acceso',
  'Payment Methods': 'Métodos de Pago',
  'Order Videos': 'Ordenar Videos',
  'Order History': 'Historial de Ordenes',
  'Payment History': 'Historial de Pago',
  Configurations: 'Configuraciones',
  Sports: 'Deportes',
  Fields: 'Campos',
  'Field Monitoring': 'Monitoreo de Campos',
  Facilities: 'Complejos',
  Cameras: 'Cámaras',
  'App Users': 'Usuarios de App',
  'Console Users': 'Usuarios de Consola',
  'User Activity': 'Actividad de Usuarios',
  Highlights: 'Destacados',
  Groups: 'Grupos',
  Roles: 'Roles',
  Notifications: 'Notificaciones',
  'Console Notifications': 'Notificaciones Console',
  'App Notifications': 'Notificationes de App',
  'Recurring Orders': 'Ordenes Recurrentes',
  Videos: 'Videos',
  Payments: 'Pagos',
  Loading: 'Cargando',
  search: 'Buscar',
  actions: 'Acciones',
  locale: 'es-ES',
  filter_by: 'filtrar por',
  minutes: {
    min_2: '2 Minutos',
    min_5: '5 Minutos',
    min_60: '60 Minutos',
    min_90: '90 Minutos',
    min_120: '120 Minutos',
    min_150: '150 Minutos',
  },
  required: {
    null: ' es requerido',
    'length 0': ' debe tener menos de 0 caracteres',
    'length 3': ' debe tener menos de 3 caracteres',
    'length 4': ' debe tener menos de 4 caracteres',
    'length 5': ' debe tener menos de 5 caracteres',
    'length 10': ' debe tener menos de 10 caracteres',
    'length 15': ' debe tener menos de 15 caracteres',
    'length 20': ' debe tener menos de 20 caracteres',
    'length 25': ' debe tener menos de 25 caracteres',
    'length 40': ' debe tener menos de 40 caracteres',
    'length 50': ' debe tener menos de 50 caracteres',
    'length 100': ' debe tener menos de 100 caracteres',
    'length 200': ' debe tener menos de 200 caracteres',
    'length 250': ' debe tener menos de 250 caracteres',
    length: 'debe ser menor o igual que ',
    email: 'Correo debe ser válido',
    nullGeneral: 'Este campo es requerido',
    invalidFormat: 'Formato inválido',
    label: '(*) Información Requerida',
    labelShort: '(*) Req.',
    needData: 'Falta datos que ingresar, por favor completelos',
  },
  tooltip: {
    new: 'Nuevo',
    save: 'Guardar',
    edit: 'Editar',
    delete: 'Borrar',
    show: 'Detalles',
    cancel: 'Cancelar',
    next_tab: 'Próximo Tab',
    back: 'Volver',
    list: 'Listado',
    back_table: 'Volver al listado',
    info: 'Información',
    search: 'Buscar',
    actions: 'Acciones',
    refresh: 'Refrescar',
    left: 'Left',
    right: 'Right',
    order: 'Ordenar',
    no_data: 'No hay videos para mostrar',
    enable: 'Habilitar',
    disable: 'Inhabilitar',
    submit: 'Enviar',
    default: 'Predeterminado',
    copied: 'Copiado',
    not_copy: 'No se pudo copiar',
    view_detail: 'ver Detalle',
    view_video: 'Ver Video',
    confirm_order: 'Confirmar Orden',
    edit_order: 'Editar Orden',
    close: 'Cerrar',
    download: 'Descargar',
    print: 'Imprimir',
    print_selection: 'Imprimir Seleciión',
    generating_pdf: 'Generando PDF',
    continue: 'Continuar',
    print_receivet: 'Imprimir recibo',
    generate_report: 'Generar Reporte',
    play_video: 'Reproducir video',
    today: 'Hoy',
    prev: 'Ant',
    next: 'Sig',
    export: 'Exporart',
    filters: 'Filtros',
    accept: 'Aceptar',
    send: 'Enviar',
    resend: 'Reenviar',
    share: 'Compartir',
    at_minute: 'en el minuto',
    enabled: 'Activado',
    disabled: 'Desactivado',
    expired: 'Expirada',
    later: 'Mas tarde',
  },
  weekdays: {
    monday: 'Lunes',
    tuesday: 'Msrtes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sabado',
    sunday: 'Domíngo',
  },
  time: {
    weeks: 'Semanas',
    week: 'Semana',
    months: 'Meses',
    month: 'Mes',
    years: 'Años',
    year: 'Año',
  },
  select: {
    role: 'Seleccionar Rol',
    facility: 'Seleccionar Complejo',
    status: 'Seleccionar Estado',
    sport: 'Seleccionar Deporte',
    field: 'Seleccionar Campo',
    chunk: 'Seleccionar Chunk',
    payment_method: 'Seleccionar Método de Pago',
    group: 'Seleccionar Grupo',
    action: 'Seleccionar acción',
    date_range: 'Seleccionar Rango de Fecha',
  },
  status: {
    status: 'Estado',
    active: 'Activo',
    inactive: 'Inactivo',
    pending: 'Pendiente',
    paid: 'Pago',
    not_paid: 'No pago',
    free: 'Gratis',
    processing: 'Procesando',
    scheduled: 'Programado',
    cancelled: 'Cancelado',
    successful: 'Exitoso',
    declined: 'Rechazado',
    refunded: 'Devuelto',
    partial_refund: 'Devolución Parcial',
    recording: 'Grabando',
    included: 'Incluido',
    not_included: 'No incluido',
  },
  users: {
    users: 'Usuarios',
    user: 'Usuario',
    dni: 'DNI',
    type: 'Tipo de Usuario',
    create: 'Crear Usuario',
    edit: 'Editar Usuario',
    show: 'Mostrar Usuario',
    list: 'Lista de Usuarios',
    username: 'Nombre de Usuario',
    name: 'Nombre',
    id: 'ID Usuario',
    lastname: 'Segundo Nombre',
    fullname: 'Nombre Completo',
    email: 'Correo',
    phone: 'Teléfono',
    gender: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    other: 'Otro',
    bio: 'Bio',
    birthday: 'Cumpleaños',
    address: 'Dirección',
    password: 'Contraseña',
    password_conf: 'Confirmar Contraseña',
    password_not_match: 'Contraseñas no coinciden',
    password_restore: 'Reestablecer contraseña',
    save_password: 'Guardar contraseña',
    account: 'Cuenta',
    security: 'Seguridad',
    info: 'Info',
    overview: 'Información',
    current_password: 'Contraseña Actual',
    new_password: 'Nueva Contraseña',
    confirm_new_password: 'Confirmar Nueva Contraseña',
    password_hint: 'Asegúrese de que tenga al menos 8 caracteres.',
    contact: 'Contacto',
    users_app: 'Usuarios de la Aplicación',
    country: 'País',
    register_date: 'Fecha de Registro',
    register_hour: 'Hora de Registro',
    last_login: 'Última Fecha de Ingrego',
    last_login_hour: 'Última Hora de Ingrego',
    website: 'Sitio web',
    user_activity: 'Actividad de Usuario',
    experience: 'Experiencia',
    age: 'Edad',
    consent: 'Consentimiento',
  },
  sports: {
    sports: 'Deportes',
    sport: 'Deporte',
    id: 'ID Deporte',
    name: 'Nombre',
    time: 'Tiempo de Juego',
    description: 'Descripción',
  },
  transactions: {
    id: 'ID de Transacción',
    transactions: 'Transacciones',
    transaction: 'Transacción',
    paid: 'Pagado',
    client: 'Cliente',
    video_date: 'Fecha de Video',
    field: 'Campo',
    start_time: 'Hora Inicio',
    end_time: 'Hora Fin',
    video_time: 'Tiempo del Video',
    amount: 'Monto',
    add_payment: 'Agregar Pago',
    pay: 'Pagar',
    continue_to_paymen: 'Ir al Pago',
    get_your_video: 'Obtén tu vídeo',
    date: 'Fecha',
    payment_date: 'Fecha de Pago',
    payment_amount: 'Monto de Pago',
    payment_method: 'Método de Pago',
    note: 'Nota',
    video_info: 'Video Info',
    user_name: 'Usuario/Nombre',
    transaction_date: 'Fecha de Transacción',
    order_date: 'Fecha de Orden',
    tax: 'Impuesto',
    discount: 'Descuento',
    pay_now: 'Pagar Ahora',
    date_issued: 'Fecha de Incidencia',
    visit_us: 'Visitanos',
    contact_us: 'Contáctanos',
    email_us: 'Envíenos un correo',
    call_us: 'Llámenos',
    price: 'Precio',
    invoice_to: 'Factura Para',
    receipt_to: 'Recibo To',
    seller: 'Vendedor',
    thanks_buy: 'Gracias por asociarse con nosotros.',
    bill_to: 'Factura Para',
    due_date: 'Fecha de vencimiento',
    order: 'Orden',
    orders: 'Ordenes',
    order_by: 'Ordenado por',
    cancel_order: '¿Cancelar Orden?',
    refund: 'Reembolsar si se hizo un pago',
    inactivate_video: 'Inactivar video',
    confirm_cancel: 'Si, Cancelar Orden!',
    from_date: 'Desde Fecha',
    to_date: 'Hasta Fecha',
    description: 'Descripción',
    monthly_report: 'Reporte Mensual',
    monthly_orders: 'Ordenes Mensuales',
    hours: 'Horas',
  },
  fields: {
    field: 'Campo',
    fields: 'Campos',
    pricing: 'Precios',
    hour: 'Hora',
    hourly_pricing: 'Precios por Hora',
    h_pricing: 'Precio H.',
    pricing_type: 'Tipo de Precios',
    general: 'General',
    chunk_frecuency: 'Frecuencia de Fragmentos',
    name: 'Nombre',
    schedule: 'Calendario',
    time_open: 'Hora Apertura',
    time_close: 'Hora Cierre',
    set_time: 'Asignar Horas',
    weekday: 'Dias de Semana',
    open: 'Abre',
    close: 'Cierra',
    update_open_time: 'Actualizar Hora de Apertura',
    update_close_time: 'Actualizar Hora de Cierre',
    clear_hourly: 'Limpiar Horario',
    link: 'Enlace',
    hourly_discount: 'Descuento por Hora',
    recording_type: 'Tipo de Grabación',
    static_video: 'Vídeo Estático',
    tracked_video: 'Vídeo Rastreado',
  },
  facilities: {
    facilities: 'Complejos',
    facility: 'Complejo',
    signer_name: 'Nombre del Firmante',
    facility_owner: 'Dueño de Complejo',
    facility_representative: 'Representante de Complejo',
    administrator: 'Administrador',
    add_comission: 'Agregar Comisión?',
    post_Sale: 'Comisión Post-Venta',
    tax_percent: 'Porcentaje de Impueto',
    logo: 'Logo',
    select_avatar: 'Selecionar Avatar',
    short_address: 'Dirección Corta',
    billing_cycle: 'Ciclo de Cobro',
    general: 'General',
    location: 'Locación',
    schedule: 'Esquema',
    enter_location: 'Introduzca su ubicación',
    monthly_min_orders: 'Ordenes Min Mensual',
    create_group: 'Crear grupo',
    add_to_group: 'Agregar a grupo',
    search_facility: 'Buscar Complejo',
    recording_weeks: 'Las grabaciones se eliminarán después de 30 dias.',
  },
  billing_cycle_types: {
    immediately: 'Inmediatamente',
    daily: 'Diariamente',
    monthly: 'Mensualmente',
    manually: 'Manualmente',
    weekly: 'Semanal',
  },
  roles: {
    roles: 'Roles',
    role: 'Rol',
    admin: 'Admin',
    facility: 'Complejo',
    representative: 'Representante',
    facility_owner: 'Dueño de Complejo',
    facility_representative: 'Representante de Complejo',
    administrator: 'Administrador',
    name: 'Nombre',
    description: 'Descripción',
    general: 'General',
    permissions: 'Permisos',
  },
  cameras: {
    cameras: 'Cámaras',
    camera: 'Cámara',
    code: 'Código',
    general: 'General',
    thumbnail: 'Miniatura',
    details: 'Detalles',
    mac_address: 'Dirección Mac',
    date: 'Fecha de Miniatura',
    last_date: 'Fecha de Última Miniatura',
    description: 'Descripción',
    name: 'Nombre',
    refresh_chunk: 'Refrescar Chunks',
  },
  videos: {
    videos: 'Videos',
    video: 'video',
    date: 'Fecha',
    time: 'Tiempo',
    highlights: 'Destacados',
    highlight: 'Destacado',
    add_order: 'Agregar Orden',
    minutes: 'Minutos',
    add_order_video: 'Agregar Orden de Video',
    get_app: ' Obten la App Gratis!',
    dont_have_fanaty: '¿No tienes Fanaty?',
    using: ' esta usando Fanaty.',
    app_all: 'Todos están usando Fanaty.',
    video_cost: 'El costo del video será recalculado al confirmar la orden',
    views: 'Vistas',
    show_qr: 'Ver QR',
    general: 'General',
    payment: 'Pago',
    first_video_viewed: 'Primer video visto',
    first_video_added: 'Primer video agregado',
    to_edit: 'para editar',
    start_time: 'Tiempo de inicio',
    make_recurring: 'Hacer recurrente',
    weekdays: 'Días de semana',
    left_video: 'Vídeo Izquierdo',
    right_video: 'Vídeo Derecho',
    left_right_video: 'Vídeo Izquierdo/Derecho',
    download_app_fanaty: 'Descargar la App Fanaty',
    continue_download: 'Para continuar, descargue la aplicación...',
    download_app: 'Descargar App',
    open_app: 'Abrir App',
    already_have: '¿Ya lo tengo?',
    need_support: '¿Necesita ayuda?',
    watch_recording: 'Ver grabación',
    dont_have: '¿Aún no tienes Fanaty?',
    no_recordings: 'No se encontraron grabaciones.',
    no_recordings_detail: 'Pruebe diferentes filtros, o si falta su grabación, por favor',
    accept_invitation: 'Aceptar Invitación',
    invited_you: 'Te invitó a unirte al grupo.',
    invitation_expired: 'Invitación Expirada',
    invalid_invitation: 'Invitación no Válida',
    ask_group: 'Solicitar otra invitación al organizador del grupo',
    sample_video: 'Video de ejemplo',
    dont_have_fanaty_yet: '¿Aún no tienes Fanaty?',
  },
  video_details: {
    duration: 'Duración',
    time: 'Tiempo',
    date: 'Fecha',
    facility: 'Complejo',
    field: 'Campo',
    sport: 'Deporte',
    amount: 'Precio del video',
    discount: 'Descuento por pre-Orden',
    final_amount: 'Importe final del cliente',
    comission: 'Comisión',
    tax: 'Impuesto de venta',
    group: 'Grupo',
    start_time: 'Tiempo de inicio',
    total_time: 'Tiempo Total',
    refunds: 'Reembolsos',
  },
  highlights: {
    highlights: 'Destacados',
    highlight: 'Destacado',
    created_date: 'Fecha Creación',
    created_time: 'Hore Creación',
    highlight_name: 'Nombre Destacado',
    created_by: 'Creado por',
    duration: 'Duración',
    category: 'Categoria',
    type: 'Tipo de Highlight',
    goal: 'Gol',
    foul: 'Falta',
    assist: 'Asistencia',
  },
  info: {
    visit_us: 'Visítanos:',
    contact_us: 'Contáctanos:',
    update_available: 'Actualización disponible',
    update_available_message: '¡Una nueva versión de Fanaty está disponible!',
    update_available_link: '¡Por favor, haga click aquí para actualizar a la última versión!',
  },
  payment_methods: {
    payment_methods: 'Métodos de Pago',
    payment_method: 'Método de Pago',
    add_card: 'Agregar Tarjeta',
    card_exp: 'La tarjeta vence en',
    edit_address: 'Editar Dirección',
    billing_address: 'Dirección de Envio',
    company_name: 'Nombre de Empresa',
    billing_email: 'Correo de Envio',
    contact: 'Contacto',
    zip_code: 'Código postal',
    edit_address_info: 'Editar tarjeta para facturación futura',
    new_card: 'Nueva Tarjeta',
    new_address_info: 'Nueva tarjeta para facturación futura',
    card_number: 'Número de Tarjeta',
    card_date: 'MM/AA',
    name_card: 'Nombre en la Tarjeta',
    expiration_date: 'Fecha de Expiración',
    cvv: 'CVV',
    cvc: 'CVC',
    default_card: 'Tarjeta Predeterminada?',
    state: 'Estado',
    edit_billing_address: 'Editar Dirección de Envio',
    edit_Card: 'Editar Tarjeta',
    payment_date: 'Fecha de Pago:',
    payment: 'Pago',
    brand: 'Marca',
    price: 'Precio',
    payment_info: 'Info Pago',
  },
  error_404: {
    page_not_found: 'Página No Encontrada',
    error_message: 'no pudimos encontrar la pagina que estas buscando',
    back_home: 'Volver al inicio',
  },
  field_app: {
    open_app: 'Abrir en la aplicación Fanaty',
    dont_have: '¿No tienes la aplicación?',
    download: '¡Descárgalo ahora!',
  },
  video_app: {
    watch_video: 'Ver Video',
    reproduced: 'Videos solo pueden ser reproducidos en la aplicación móvil.',
    video_not_found: 'El video no existe!',
    content_not_exist: 'El contenido ya no existe!',
    content_delete: 'Contenido eliminado',
    match_deleted: 'Partido eliminado',
  },
  login: {
    welcome: 'Bienvenido a',
    message: 'Ingresa a tu cuenta y comienza la aventura',
    username: 'Usuario',
    password: 'Contraseña',
    forgot_password: 'Olvidé mi contraseña',
    login: 'Ingresar',
    enter_phone_email: 'Ingrese Teléfono o Correo Electrónico',
    enter_phone_email_detail: 'Ingrese su número de teléfono o correo electrónico para acceder a su cuenta',
    enter_password: 'Ingresa la contraseña de su cuenta',
    email_code_to: 'Enviar codigo a ',
    text_code_to: 'Enviar codigo a ',
    or_email: 'O inicie sesión con el usuario/correo electrónico',
    or_phone: 'O inicie sesión con número de teléfono',
    or_code: 'O',
    enter_detail: 'Al continuar, usted acepta recibir correos electrónicos, llamadas, mensajes de WhatsApp o SMS, incluso por marcador automático, de Fanaty y sus afiliados al número o correo electrónico proporcionado. Envía un mensaje de texto con la palabra "DETENER" para optar por no participar.',
    powered_by: 'Desarrollado por',
    log_in: 'Ingresar',
    log_in_detail: 'Accede a tu cuenta con tu teléfono o dirección de correo electrónico y contraseña',
    sign_in_another_account: 'Ingresar con otra cuenta',
  },
  confirmation_code: {
    confirmation_code: 'Código de confirmación',
    access_code: 'Código de Acceso',
    enter_code: 'Introduce el código de confirmación que te enviamos',
    didnt_receive: '¿No recibiste el código?',
    or_password: 'O inicia sesión con contraseña',
    or_code: 'O inicie sesión con el código de confirmación',
  },
  access_code: {
    access_code: 'Código de Acceso',
    one_time_password: 'Contraseña Temporal',
    enter_one_time_password: 'Ingrese la contraseña temporal que te enviamos a ',
    log_in: 'Iniciar sesión',
    enter_code: 'Introduce el código de Acceso que te enviamos',
    didnt_receive: 'Reenviar código',
    or_password: 'O inicia sesión con contraseña',
    or_code: 'O',
    login_with_password: 'Iniciar sesión con contraseña',
    sign_in_another_account: 'Ingresar con otra cuenta',
    in: 'en',
    seconds: 'segundos',
  },
  forgot_password: {
    change_password: 'Cambiar la contraseña',
    change_password_detail: 'Introduzca la dirección de correo o el número de teléfono de su cuenta para recuperar su contraseña',
    reset_password: 'Restablecer contraseña',
    will_receive_email: 'Recibirá un correo electrónico con las instrucciones para restablecer la contraseña',
  },
  create_account: {
    create_account: 'Crear Cuenta',
    enter_info: 'Ingrese la información de su perfil',
    first_name: 'Nombre',
    last_name: 'Apellido',
    phone_number: 'Número de teléfono',
    email_address: 'Dirección de correo electrónico',
    password: 'Contraseña',
    confirm_password: 'Confirmar contraseña',
    accept_terms: 'Acepto los',
    terms: 'términos y condiciones',
  },
  is_this_you: {
    is_this_you: '¿Este Eres tu?',
    found_account: 'Encontramos una cuenta existente vinculada al número de teléfono que ingresó.',
    yes: 'Si, soy yo.',
    no: 'No, este no soy yo.',
  },
  video_cancelled: {
    video_cancelled: 'Vídeo cancelado',
    game_cancelled: 'Juego cancelado',
    game_info: 'Este juego ha sido cancelado. Buscar juegos para ver si se creó uno nuevo.',
    search_games: 'Buscar juegos',
    search_videos: 'Buscar videos',
    download_app: 'Descarga la aplicación',
    not_available: 'Vídeo no disponible',
    not_valid: 'Vídeo no valido',
    click_search_videos: 'Haga clic en buscar videos para encontrar otros.',
  },
  video_quality: {
    video_quality: 'Calidad de video',
    video_quality_detail: 'Elige cómo quieres que se graben las partidas.',
    premium: 'Premium',
    basic: 'Básico',
    premium_video: 'La cámara sigue la acción automáticamente.',
    free_video: 'Video gratis sin movimiento.',
  },
  pay_video: {
    pay_video: 'Pagar vídeo premium',
    pay_video_detail: 'La cámara sigue la acción automáticamente.',
    card_information: 'Información de la tarjeta',
    save_credit_card: 'Guarde la tarjeta de crédito para futuros pagos.',
    pay: 'Pagar',
    and_join: 'y Únite',
    without_premium_video: 'Unirse sin video premium',
    credit_card_new: 'Nueva Tarjeta de crédito',
    credit_card: 'Tarjeta de crédito',
    apple_pay: 'Apple pay',
    new_card: 'Nueva Tarjeta',
    saved_card: 'Tarjeta Registrada',
    ends: 'Termina',
    exp: 'Vence',
  },
  player_actions: {
    leave: 'Dejar',
    add_guest: 'Agregar Invitado',
    mark_as_paid: 'Marcar como Pagado',
    remove: 'Remover',
    paid: 'Pagado',
    organizer: 'Organizador',
    change_quantity: 'Cambiar cantidad',
    players_quantity: 'Cantidad de Jugadores',
  },
  add_guest: {
    guest: 'Invitado',
    add_guest: 'Agregar Invitado',
    guests_name: 'nombre del invitado',
    guest_info: 'Los invitados son jugadores sin cuenta. Agregue su teléfono o correo electrónico y se enviará un enlace del juego.',
    optional: 'Opcional',
    enter_phone_email: 'Ingrese teléfono o correo electrónico',
  },
  landing: {
    share_game: 'Compartir juego',
    rate_players: 'Calificar jugadores',
    full_video: 'Video completo',
    match_name: 'Nombre del Juego',
    match_description: 'Descripción del Juego',
    report_issue: 'Reportar problema',
    describe_your_issue: 'Describe tu problema...',
    join_game: 'Unirse',
  },
  deek_game: {
    join_for: 'Unirse por',
    join: 'Unirse',
  },
  settings: {
    logout: 'Cerrar Sesión',
    change_password: 'Cambiar Contraseña',
    report: 'Reportar',
  },
  table: {
    item_page: 'Filas por página:',
    sort_by: 'Ordenar Por',
    no_data: 'No hay data disponible',
    loading_text: 'Cargando data...',
  },
  miscellaneous: {
    you_are_not_authorized: 'No estas autorizado',
    you_dont_have_permission: 'No tienes permiso para acceder a esta página. Vete al inicio',
    back_to_home: 'De vuelta al inicio',
    page_not_found: 'Página no encontrada',
    we_couldnt_find_the_page: 'no pudimos encontrar la pagina que estas buscando',
    no_video_on_web: 'Para ver el video descarga la aplicación móvil',
  },
  notifications: {
    notifications: 'Notificaciones',
    notification: 'Notificación',
    title_spa: 'Título ESP',
    title_eng: 'Título ENG',
    description_spa: 'Descripción ESP',
    description_eng: 'Descripción ENG',
    button_label_spa: 'Etiqueta de botón ESP',
    button_label_eng: 'Etiqueta de botón ENG',
    image: 'Imagen',
    background: 'Fondo',
    handled_action: 'Acción manejada',
    show_modal: 'Mostrar Modal',
    select_background: 'Seleccione un fondo',
    select_image: 'Seleccione una imagen',
    only_fanaty_user: 'Solo usuario Fanaty?',
    title: 'Título',
    text: 'Texto',
    text_html: 'Text HTML',
    border: 'Borde',
    color: 'Color',
    dismissible: 'Cerrable',
    outlined: 'Contorneado',
    colored_text: 'Texto Coloreado',
    type: 'Tipo',
    icon: 'Icono',
    all_groups: 'Todos los Grupos',
    all_roles: 'Todos los Roles',
    send_by: 'Enviada por',
    created_on: 'Creada el',
  },
  groups: {
    groups: 'Grupos',
    group: 'Grupo',
    min_orders_amount: 'Cantidad mín. de pedido',
    min_orders_filter: 'Filtro de pedido mín.',
    all_groups: 'Todos los grupos',
    monthly_charge_amount: 'Monto Mensual de Suscripción',
    order_reminder_minutes: 'Minutos de recordatorio de SMS',
    notes: 'Notas',
    price_plan: 'Plan de precios',
    price_range: 'Rango de precios',
    hour_price_range: 'Rango de Precio por Hora',
    from: 'De',
    to: 'a',
    up: 'en adelante',
    hours: 'horas',
    hour: 'hora',
    add_range: 'Agregar rango',
    range_close: 'Debe completar el rango de precios!',
    fixed_minimum_amount: 'Monto mínimo fijo',
    price_hour_range: 'Precio por rango de horas',
    only_hourly_price: 'Precio por hora único',
  },
  statistics: {
    this_month: 'Este Mes',
    overview: 'Nro de Ordenes por Semana',
    orders: 'Ordenes',
    order_number: 'Número de Ordenes',
    app_users_number: 'Usuarios Activos en App',
    app_users_number_tooltip: 'Cantidad de Usuarios que interactúan con cualquier video de los diferentes complejos en la App',
    comissions: 'Comisiones',
    active_facility: 'Complejos Activos',
    active_field: 'Campos Activos',
    active_camera: 'Camaras Activas',
    active_app_users: 'Usuarios de App Activos',
    active_app_users_tooltip: 'Cantidad de Usuarios que interactúan con cualquier video en la App',
    app_sessions: 'Tasa de Rebote de Usuarios en App',
    app_sessions_tooltip: 'Porcentaje de usuarios que entran a la app pero no interactúan con ninguno de los videos',
    number_videos: 'Vídeos Reproducidos',
    number_videos_tooltip: 'Vídeos Reproducidos',
    unique_views: 'Vistas Unicas',
    unique_views_tooltip: 'Cantidad de reproducciones únicas que tuvo un video de un usuario',
    avg_per_video: 'Avg por video',
    avg_per_video_tooltip: 'Promedio de reproducciones de videos reproducidos',
    sales_by_facility: 'Ventas por complejo',
    actions_app_by_user: 'Acciones en App por Usuario',
    actions_app_by_user_tooltip: 'Top 25 de usuarios con más interacciones con los videos de la app',
    registered_app_users: 'Usuarios en App Registrados',
    registered_app_users_by_facility: 'Usuarios Registrados en App por Complejo',
    unique_views_by_facility: 'Vistas Únicas por Complejo',
    refunds_amount: 'Monto de Reembolsos',
  },
  alert_types: {
    success: 'Exito',
    error: 'Error',
    warning: 'Alerta',
    info: 'Info',
  },
  border_types: {
    left: 'Izquierda',
    right: 'Derecha',
    top: 'Arriba',
    bottom: 'Abajo',
  },
  colors: {
    red: 'Rojo',
    orange: 'Naranja',
    yellow: 'Amarillo',
    green: 'Verde',
    blue: 'Azul',
    purple: 'Violeta',
  },
  date_range: {
    this_year: 'Este año',
    this_month: 'Este mes',
    last_month: 'Último mes',
    this_week: 'Esta semana',
    last_week: 'Último week',
    custom: 'Personalisado',
  },
  notify_players: {
    notify_players: 'Notificar a los Jugadores',
    send_sms: 'Enviar SMS',
    phone: 'Número de teléfono del jugador',
    add_phone: 'Añadir teléfono',
    message: 'Se enviará un enlace de video por SMS a cada jugador agregado.',
    skip: 'Saltar',
    requiredPhone: 'Debe agregar al menos un número de teléfono',
    you_must_add: 'Debes agregar un número de teléfono si haces la orden recurrente.',
    phone_numbers: 'Números de teléfono',
  },
  dashboard: {
    shared_highlights: 'Highlights Compartidos',
    numbe_of_highlights: 'Cantidad de enlaces de highlights compartidos',
    created_highlights: 'Highlights Creados',
    numbe_of_highlights_created: 'Cantidad de highlights creados',
    revenue: 'Ganancia',
    orders_revenue: 'Ingresos por pedidos durante un período de tiempo',
  },
  reservations: {
    reservations: 'Reservaciones',
    reservation: 'Reservación',
    new_reservation: 'Nueva Reservación',
    reservation_detail: 'Detalle de Reservación',
    reservation_save: 'Guardar Reservación',
    cancel_reservation: 'Cancelar Reservación',
    recurring_detail: 'Detalles de la Recurrencia',
    booking_details: 'Detalles de reserva',
    booking: 'Reserva',
    recurring: 'recurrente',
    one_day: 'Un día',
    pick_up: 'Recogidas',
    pick_up_match: 'Partido Abierto',
    regular_match: 'Partido Regular',
    group_match: 'Partido Grupal',
    regular: 'Regular',
    other: 'Otro',
    academy: 'Academia',
    academies: 'Academias',
    match: 'Partido',
    league: 'Liga',
    birthday: 'Cumpleaños',
    tournament: 'Torneo',
    insights: 'Perspectivas',
    person: 'Persona',
    include_video: 'Incluir Video',
    player: 'Jugador',
    per_player: 'Por Jugador',
    all_members: 'Todos los miembros del grupo',
    confirmed_players: 'Jugadores confirmados',
    add_group_players: 'Agregar jugadores al grupo',
    add_other_players: 'Agregar otros jugadores',
    balance: 'Balance',
    mark_all_paid: 'Marca todos pagado',
    no_show: 'No Mostrar',
    other_reason: 'Otra Razón',
    reason: 'Razón',
    incomplete_reservations: 'Reservaciones Incompletas',
    repeat_n_weeks: 'Repetir cada N semanas',
    frequency: 'Frequency',
    incomplete_reason: 'Razón incompleta',
    not_created: 'No creadas por conflictos',
    contact_name: 'Nombre de Contacto',
    contact: 'Contacto',
    contacts: 'Contactos',
    contact_phone_numbers: 'Números de teléfono de contacto',
    enter_name: '',
    enter_name_phone: 'Ingrese nombre o teléfono',
    enter_name_phone_email: 'Ingrese nombre, teléfono o correo electrónico',
    add_new_contact: 'Añadir nuevo contacto',
    add_new_group: 'Añadir nuevo grupo de jugadores',
    group_match_detail: 'Detalle del partido de grupo',
    pick_up_match_detail: 'Detalle del partido abierto',
    regular_match_detail: 'Detalle de partido regular',
    recurrency_detail: 'Detalle de recurrencia',
    skill_level: 'Nivel de habilidad',
    price_per_player: 'Precio por jugador',
    min_players: 'Min jugadores',
    max_players: 'Max jugadores',
    hours_before_cancel: 'Horas antes de cancelar el partido (Si los jugadores mínimos no se unen)',
    reservation_link_copied: '¡Enlace de reservación copiado!',
    reservation_link_error: '¡Error al copiar el enlace de reservación!',
    free_cancellation: 'Cancelación gratuita antes de',
    create_highlights: 'Crear Highlights',
    are_you_sure: '¿Estás seguro?',
    this_will_cancel: 'Esto cancelará la reservación.',
    yes_cancel_reservation: 'Sí, cancelar reservación',
    no_kee_reservation: 'No, mantener reservación',
    reservation_has_been_cancelled: 'La reserva ha sido cancelada.',
    reservation_was_paid: 'La reserva fue pagada en su totalidad.',
    reservation_is_pending: 'La reserva está pendiente de pago.',
    reservation_was_declined: 'El pago fue rechazado, inténtalo de nuevo.',
    video_recording_title: 'Grabación de vídeo',
    video_recording_text: 'Esto será grabado con nuestra cámara de video con inteligencia artificial.',
    non_refundable: 'Esta reserva no es reembolsable.',
    this_would_not_be_recorded: 'Esto no se grabaría con nuestra cámara de vídeo con inteligencia artificial.',
    pay_for_booking: 'Paga la reserva aquí antes de',
    to_activate_video: 'para activar la grabación de vídeo.',
    reservation_has_been_cancelled_since: 'La grabación ha sido cancelada ya que el pago no se realizó aquí antes de la hora de inicio.',
    service_fee_and_taxes: 'Tarifa de servicio e impuestos',
    confirm_and_pay: 'Confirmar y pagar',
    you_are_leaving: 'Estás dejando el flujo de pago, ¿Quieres cerrar sesión?',
  },
  pay_dialog: {
    select_payment_method: 'Selecciona un método de pago',
    add_payment_method: 'Agrega un método de pago',
    credit_card_number: 'NÚMERO DE TARJETA DE CRÉDITO',
    by_pressing_pay: 'Al presionar Pagar, acepta los',
    and: 'y la',
    terms_and_conditions: 'términos y condiciones',
    privacy_policy: 'política de privacidad',
    reservation_will_be_charged: 'La reserva se cargará el día',
    reservation_will_be_charged_now: 'La reserva se cargará pronto',
    card_ending_in: 'en la tarjeta terminada en',
    checkout: 'Checkout',
    get_gremium_video: 'Obtener el Video Premium',
  },
  deletion_request: {
    deletion_request: 'Solicitud de eliminación',
    deletion_request_text: '¿Quieres eliminar tu cuenta?',
    were_sorry: 'Sentimos verte partir. Tenga en cuenta una vez que se elimine su cuenta:',
    no_longer_record: 'Ya no tendremos ningún registro de sus pedidos o mensajes con nuestro equipo de soporte.',
    no_email_sms: 'Ya no recibirás correos electrónicos ni SMS de notificación.',
    once_account_deletion_process: 'Una vez que se inicia el proceso de eliminación de la cuenta, no se puede revertir y ya no tendrá acceso a su cuenta ni a las funciones asociadas.',
    delete_account: 'Eliminar cuenta',
    enter_phone_email: 'Por favor introduce tu teléfono o email',
    by_deleting: 'Al eliminar esta cuenta, acepta los Términos y condiciones de Fanaty.',
    received_your_request: 'Hemos recibido su Solicitud.',
    confirmation_email: 'Nuestro equipo le enviará un correo electrónico de confirmación en breve.',
    are_you_sure: '¿Estás seguro de que quieres eliminar tu cuenta?',
  },
  game: {
    match_detail: 'Detalle del partido',
    create_match: 'Crear partido',
    choose_team: 'Elegir Equipo',
    pay_with: 'Pagar con:',
    pay_booking: 'Pagar reserva',
    pay_my_part: 'Pagar mi parte',
    ranked: 'Ranqueado',
    ranked_msg: 'El resultado del partido afecta tu nivel',
    not_ranked: 'No rankeado',
    not_ranked_msg: 'El resultado del partido no afecta tu nivel',
    with_video: 'Con video',
    with_video_msg: 'El partido será grabado',
    without_video: 'Sin video',
    without_video_msg: 'El partido no será grabado',
    slot_free: 'Libre',
    slot_selected: 'Lugar seleccionado',
    slot_empty: 'Libre',
    available: 'Disponible',
    slot_empty_no_selectable: 'No seleccionable',
    team: 'Equipo',
    my_team: 'Mi equipo',
    rival_team: 'Equipo rival',
    enter_results: '¡Ingresar resultados!',
    enter_results_msg: 'Para que quede en tu historial',
    unjoin: 'Salir del partido',
    i_want_to_play: 'Quiero jugar',
    cancellation_policy: 'Política de cancelación',
    cancellation_policy_msg: 'Cancelación sin cargo antes de',
    cancellation_policy_msg_short: 'Cancelación sin cargo',
    non_cancellable: 'No cancellable',
    non_refundable: 'No reembolsable',
    before: 'antes de',
    share_invite: 'Compartir invitación',
    joined_match: '¡Te has unido al partido!',
    share_link: 'Comparte el link de invitación si quieres invitar a un amigo.',
    joined_reservation: '¡Partido creado!',
    share_link_reservation: 'Comparte el link de invitación con el resto de los jugadores para que se unan.',
    not_now: 'Ahora no',
    any_level: 'Cualquier nivel',
    are_you_sure: '¿Estás seguro de que quieres salir del partido?',
    are_you_sure1: '¿Estás seguro de que quieres',
    are_you_sure2: 'salir del partido?',
    are_you_sure_msg: 'Si cancelas, no podrás participar en el partido y no se te hará un reembolso por estar fuera del período de cancelación.',
    terms_and_conditions: 'Términos y condiciones',
    privacy_policy: 'Política de privacidad',
    by_paying: 'Al pagar aceptas los',
    and: 'y las',
    notify_new_slot: 'Te cambiamos de lugar porque ya estaba ocupado.',
    review_details: 'Revisar detalles',
    how_to_pay: 'Cómo pagar',
    pay_all: 'Pagar toda la reserva',
    pay_all_team: 'Pagar por todo mi equipo',
    split_price_participants: 'Dividir entre participantes',
    split_price_participants_team: 'Dividir entre mi equipo',
    at_least: 'Al menos',
    participants: 'participantes',
    participants_team: 'compañeros de equipo',
    divide_by_at_least: 'Dividir entre al menos...',
    custom_players_quantity: 'O ingrese el número de participantes',
    of: 'de',
    temporarily_hold: 'Retendremos temporalmente',
    if_fewer_than: 'Si pagan menos de',
    participants_pay_by: 'participantes antes del',
    covered_from: 'se cubrirán con el total retenido. Si pagan más de',
    everyone_will_have: 'se le reducirá a todos el pago',
    cancelled_match: 'Cancelaste el partido',
    leaved_match: 'Saliste del partido',
    match_cancelled: 'Partido cancelado',
    match_has_been_cancelled: 'El partido ha sido cancelado',
    choose_team_play: 'Selecciona con qué equipo jugaras',
    choose_team_name: 'O ingresa el nombre de tu nuevo equipo',
    create_your_team: 'Crea tu equipo',
    only_organizer: 'Sólo los organizadores del partido pueden confirmar la reserva.',
    add_profile_picture: 'Agrega una foto de perfil para que otros jugadores puedan reconocerte al elegir a los mejores jugadores.',
    profile_picture: 'Foto de perfil',
    add_photo: 'Agregar foto',
  },
  highlight_view: {
    highlight_created_toast: 'El highlight se está creando',
    move_camera_description: ' Usa tus dedos para mover la cámara',
    cam: 'Cámara',
    start_highlight: 'Start Highlight',
    stop_highlight: 'Stop Highlight',
    all_highlights: 'Todos los Highlights',
    delete: 'Eliminar',
    save: 'Guardar',
    saved: 'Guardado',
    share: 'Compartir',
    name_you_highlight: 'Nombra tu highlight',
    create_highlight_placeholder: 'Escribe el nombre del highlight',
    cancel: 'Cancelar',
    are_you_sure: '¿Estás seguro?',
    highlight_deleted_description_1: 'Este highlight será eliminado.',
    highlight_deleted_description_2: 'Nadie podrá acceder a él.',
    remove_highlight: 'Eliminar Highlight',
    unsave_highlight_description_1: 'Perderás acceso a este highlight.',
    unsave_highlight_description_2: 'Ya no será visible en tu perfil.',
    remove_bookmark: 'Eliminar marcador',
    create_highlight_description_1: '¡Es hora de volverse viral! Compártelo con tus amigos,',
    create_highlight_description_2: 'equipo o en redes sociales.',
    later: 'Más tarde',
    creating: 'creando',
    share_now: 'Compartir ahora',
  },
}
