/* eslint-disable import/no-cycle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import { apiHttp } from '../axiosApi'
import {
  userLogin,
  userLogout,
  userResetPassword,
  userChangePassword,
  userRegister,
  userUpdate,
  landingVerify,
  landingLogin,
  userSendCodeSMS,
  userSendCodeEmail,
  userSendConfirmationEmail,
  userCheckVerificationEmail,
  userCheckVerificationSMS,
  userActivity,
  userDeleteAccount,
} from '../config/apiRoute'

export const loginApi = ({ username, password }) =>
  apiHttp(
    'POST',
    `${userLogin}`,
    { username, password },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
      },
    },
  )

export const verifyUser = info =>
  apiHttp(
    'POST',
    `${landingVerify}`,
    { info },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`${info}`)}`,
      },
    },
  )

export const loginUser = info =>
  apiHttp(
    'POST',
    `${landingLogin}`,
    { info },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Basic ${btoa(`${info}`)}`,
      },
    },
  )

export const logoutUser = async info => apiHttp('POST', `${userLogout}`, info)
export const registerUser = async info => apiHttp('POST', `${userRegister}`, { info })
export const updateUser = async info => apiHttp('PUT', `${userUpdate}`, { info })
export const sendCodeSMSUser = async info => apiHttp('POST', `${userSendCodeSMS}`, { info })
export const sendCodeEmailUser = async info => apiHttp('POST', `${userSendCodeEmail}`, { info })
export const sendConfirmationEmailUser = async info => apiHttp('POST', `${userSendConfirmationEmail}`, { info })
export const checkVerificationEmailUser = async (email, token) => apiHttp('GET', `${userCheckVerificationEmail}${email}/${token}`)
export const checkVerificationSMSUser = async info => apiHttp('POST', `${userCheckVerificationSMS}`, null, { params: info })

// const resetPassword = async userId => apiHttp('GET', `${userResetPassword}${userId}`)
export const resetPassword = async userId => apiHttp('GET', `${userResetPassword}${userId}`)
export const deleteUserAccount = async () => apiHttp('PUT', `${userDeleteAccount}`)
export const changePassword = async (userId, oldPass, newPass) =>
  apiHttp('PUT', `${userChangePassword}${userId}?old_password=${oldPass}&new_password=${newPass}`)
export const logUserActivity = async body => apiHttp('POST', `${userActivity}`, body, {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
